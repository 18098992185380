<template lang="">
  <modal-padrao
    ref="modal-setores"
    max-width="70%"
    :titulo="
      $t(
        'modulos.tipo_instrumento.tabela.sequencia_de_setores.associar_desassociar'
      )
    "
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    @modal-fechado="modalFechado"
    @ok="associarSetores"
  >
    <Setores
      :modalVisivel="show"
      v-model="selecionados"
      :max-width="1000"
      :titulo="$t('componentes.input_setor.titulo_modal')"
      :titulo-ok="$t('geral.botoes.confirmar')"
    />
  </modal-padrao>
</template>
<script>
import Setores from './ModalSetoresTabela.vue';
import _ from 'lodash';
import helpers from '@common/utils/helpers';

export default {
  components: { Setores },
  data() {
    return {
      show: false,
      selecionados: [],
      referenciaForm: {},
      participante: null,
      localExecucao: null,
    };
  },
  methods: {
    teste() {},
    abrirModal: async function (form, participante, localExecucao) {
      // this.selecionados = _.cloneDeep(form.tipoInstrumentoSequenciaSetor
      //   .filter((el) => el.participanteId == participante && el.localExecucaoId == localExecucao));
      this.referenciaForm = form;
      this.participante = participante;
      this.localExecucao = localExecucao;
      this.show = true;
      this.$refs['modal-setores'].abrirModal();
    },
    associarSetores() {
      this.selecionados.forEach((setorSelecionado) => {
        const setor = _.cloneDeep(setorSelecionado);
        // let setorJaInserido = this.referenciaForm.tipoInstrumentoSequenciaSetor.some(
        //   (el) => el.id == setor.id && el.participanteId == this.participante && el.localExecucaoId == this.localExecucao
        // );

        // if (!setorJaInserido) {
        setor.participanteId = this.participante;
        setor.localExecucaoId = this.localExecucao;
        setor.gatilhoMovimentacao = 'SemGatilho';
        setor.ordem =
          this.referenciaForm.tipoInstrumentoSequenciaSetor.length + 1;
        setor.setorId = setor.id;
        setor.id = helpers.gerarNumeroAleatorio();
        this.referenciaForm.tipoInstrumentoSequenciaSetor.push(setor);
        // }
      });
      this.selecionados = [];
      this.$refs['modal-setores'].fecharModal();
      this.$emit('atualizarTabela');
    },
    modalFechado() {
      this.selecionados = [];
      this.show = false;
    }
  },
};
</script>
