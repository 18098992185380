<template lang="">
  <div class="mt-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <div>
        <div class="d-flex align-center">
          <botao-padrao
            v-if="podeExcluir"
            color="secondary"
            outlined
            :disabled="!tabela.selecionados.length"
            @click="movimentarItem('cima')"
          >
            <v-icon> $arrowUp </v-icon>
          </botao-padrao>

          <botao-padrao
            v-if="podeExcluir"
            color="secondary"
            outlined
            class="mx-2"
            :disabled="!tabela.selecionados.length"
            @click="movimentarItem('baixo')"
          >
            <v-icon> $arrowDown </v-icon>
          </botao-padrao>

          <botao-padrao
            v-if="podeExcluir"
            class="my-2 ml-2"
            outlined
            color="danger"
            @click="excluirRegistros"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
            {{ $t('geral.botoes.excluir') }}
          </botao-padrao>
        </div>
      </div>
      <div class="d-flex align-md-center flex-column flex-md-row">
        <div class="d-flex align-center">
          <span
            class="mr-1 subtitle-1"
          >{{
            $t(
              'modulos.tipo_instrumento.tabela.sequencia_de_setores.participante'
            )
          }}:</span>
          <input-select
            v-model="participante"
            class="mr-2"
            estilo-customizado="width: 300px; font-size: 0.90rem; font-weight: 500;"
            sem-label
            :loading="loadingParticipante"
            :options="opcoes.participantes"
            :placeholder="
              $t(
                'modulos.tipo_instrumento.tabela.sequencia_de_setores.participante'
              )
            "
            :disabled="visualizar || !participanteLogadoConta"
          />
        </div>
        <div class="d-flex align-center">
          <span
            class="mr-1 subtitle-1"
          >{{
            $t(
              'modulos.tipo_instrumento.tabela.sequencia_de_setores.local_execucao'
            )
          }}:</span>
          <input-select
            v-model="localExecucao"
            class="mr-2"
            estilo-customizado="width: 200px; font-size: 0.90rem; font-weight: 500;"
            sem-label
            :loading="loadingLocalExecucao"
            :options="opcoes.locaisExecucao"
            :placeholder="
              $t(
                'modulos.tipo_instrumento.tabela.sequencia_de_setores.local_execucao'
              )
            "
            :disabled="visualizar || !participanteLogadoConta"
          />
        </div>
        <botao-padrao
          v-if="participanteLogadoConta"
          :disabled="visualizar || !participanteLogadoConta"
          @click="associarDessasociar"
        >
          {{ $t('modulos.tipo_instrumento.botoes.associar_desassociar') }}
        </botao-padrao>
      </div>
    </div>
    <tabela-padrao
      ref="tabela"
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="colunasTabela"
      :mostrar-checkbox="participanteLogadoConta"
      :sem-acoes="!participanteLogadoConta"
      :por-pagina="tabela.porPagina"
      sem-paginacao
      :disabled="visualizar || !participanteLogadoConta"
    >
      <template v-slot:item.gatilhosMovimentacao="{ item }">
        <input-select
          v-model="verificarPrimeiraLinha(item).gatilhoMovimentacao"
          class="flex-fill input-tabela-campos-adicionais my-1"
          :disabled="visualizar || !participanteLogadoConta || item.index === 0"
          sem-label
          :clearable="false"
          :options="filtrarEnumGatilhosMovimentacao(item)"
          flat
          solo
          background-color="transparent"
          hide-details
          estilo-customizado="font-size: 0.90rem; font-weight: 500;"
        />
      </template>
    </tabela-padrao>
    <ModalSetores
      ref="modal-setores"
      @atualizarTabela="atualizarTabela"
    />
  </div>
</template>
<script>
import ModalSetores from './Modal/ModalSetores.vue';
import helpers from '@common/utils/helpers.js';
import { ParticipanteService, LocalExecucaoService } from '@common/services';
import { DropdownModel } from '@common/models/geral/DropdownModel';

export default {
  components: { ModalSetores },
  props: {
    form: {},
    participanteLogadoConta: { type: Boolean, default: false },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: [],
        selecionados: [],
      },
      opcoes: {
        gatilhosMovimentacao: helpers.GatilhoMovimentacaoSetorEnum,
        participantes: [],
        locaisExecucao: [],
      },
      loadingParticipante: true,
      loadingLocalExecucao: true,
      participante: this.$store.getters['Autenticacao/participanteSelecionado'],
      localExecucao: null,
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    colunasTabela: function () {
      const colunas = [
        {
          value: 'nome',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.sequencia_de_setores.nome'
          ),
          width: 300,
          sortable: true,
        },
        {
          value: 'tipoSetor.descricao',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.sequencia_de_setores.tipo'
          ),
          sortable: true,
          width: 300,
        },
        // {
        //   value: 'tipoSetor.posicao',
        //   text: this.$t(
        //     'modulos.tipo_instrumento.tabela.sequencia_de_setores.posicao'
        //   ),
        //   sortable: true,
        //   width: 150,
        //   formatter: (v) =>
        //     helpers.PosicaoSequenciaSetorEnum.find((el) => el.value == v)?.text,
        // },
        {
          value: 'participante.nome',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.sequencia_de_setores.participante'
          ),
          width: 280,
        },
        {
          value: 'sigla',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.sequencia_de_setores.sigla'
          ),
          sortable: true,
          width: 300,
        },
        {
          value: 'gatilhosMovimentacao',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.sequencia_de_setores.gatilhos_movimentacao'
          ),
          sortable: true,
        },
      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
  },
  watch: {
    participante() {
      this.atualizarTabela();
    },
    localExecucao() {
      this.atualizarTabela();
    },
  },
  mounted: async function () {
    await this.buscarParticipantes();
    await this.buscarLocalExecucao();
    this.atualizarTabela();
  },
  methods: {
    verificarPrimeiraLinha(item) {
      if (item.index === 0)
        item.item.gatilhoMovimentacao = 'OrdemServicoCriada';
      return item.item;
    },
    associarDessasociar() {
      this.$refs['modal-setores'].abrirModal(
        this.form,
        this.participante,
        this.localExecucao
      );
    },
    abrirEditar(faixa) {
      this.$refs['modal-faixa'].abrirModal(faixa);
    },
    atualizarTabela() {
      this.tabela.selecionados = [];
      this.tabela.dados = this.form.tipoInstrumentoSequenciaSetor.filter(
        (el) =>
          el.participanteId == this.participante &&
          el.localExecucaoId == this.localExecucao
      );
    },
    movimentarItem: function (direcao) {
      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.form.tipoInstrumentoSequenciaSetor.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) => a.index - b.index);

      itensSelecionadosComIndex.forEach((item) => {
        let indiceItemTroca = this.buscarItemProximo(
          this.form.tipoInstrumentoSequenciaSetor,
          item.index,
          direcao
        );

        if (indiceItemTroca == null) return;
        const novaLista = [...this.form.tipoInstrumentoSequenciaSetor];
        const itemTroca = novaLista[indiceItemTroca];

        novaLista[indiceItemTroca] = novaLista[item.index];
        novaLista[item.index] = itemTroca;
        this.form.tipoInstrumentoSequenciaSetor = [...novaLista];
        this.tabela.dados = this.form.tipoInstrumentoSequenciaSetor.filter(
          (el) =>
            el.participanteId == this.participante &&
            el.localExecucaoId == this.localExecucao
        );
      });
    },
    buscarItemProximo(array, indice, direcao) {
      let indiceMaisProximo = -1;
      array.forEach((itemTroca, index) => {
        if (direcao == 'baixo') {
          if (
            itemTroca.participanteId == this.participante &&
            itemTroca.localExecucaoId == this.localExecucao &&
            index > indice
          ) {
            if (indiceMaisProximo == -1) indiceMaisProximo = index;
          }
        }
        if (direcao == 'cima') {
          if (
            itemTroca.participanteId == this.participante &&
            itemTroca.localExecucaoId == this.localExecucao &&
            index < indice
          ) {
            if (indiceMaisProximo < index) indiceMaisProximo = index;
            if (indiceMaisProximo == -1) indiceMaisProximo = index;
          }
        }
      });
      if (indiceMaisProximo == -1) return null;
      return indiceMaisProximo;
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.tipoInstrumentoSequenciaSetor.splice(
            this.form.tipoInstrumentoSequenciaSetor.indexOf(element),
            1
          );
        });
        this.atualizarTabela();
      });
    },
    buscarParticipantes: async function () {
      await ParticipanteService.listarParticipantesConta({
        FlagParticipanteConta: true,
      }).then((res) => {
        this.opcoes.participantes = new DropdownModel(res.data.items);
        this.loadingParticipante = false;
      });
    },
    buscarLocalExecucao: async function () {
      await LocalExecucaoService.listar({ sort: 'instalacaoCliente' }).then(
        (res) => {
          this.opcoes.locaisExecucao = new DropdownModel(res.data);
          this.localExecucao = this.opcoes.locaisExecucao[0]?.value;
          this.loadingLocalExecucao = false;
        }
      );
    },
    filtrarEnumGatilhosMovimentacao: function (item) {
      return this.opcoes.gatilhosMovimentacao?.filter((el) => {
        if (item.index !== 0) return el.value !== 'OrdemServicoCriada';
        return [];
      });
    },
  },
};
</script>
