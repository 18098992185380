<template>
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-text
          v-model="form.nome"
          class="col-12 col-md-7"
          :label="$t('modulos.tipo_instrumento.formulario.nome')"
          obrigatorio
          trim
          :disabled="visualizarOuParticipanteLogadoConta"
        />
        <InputClassificacaoInstrumento
          v-model="form.classificacaoInstrumento"
          class="col-12 col-md-3"
          :label="$t('modulos.tipo_instrumento.formulario.classificacao')"
          :multiplos="false"
          obrigatorio
          :disabled="visualizarOuParticipanteLogadoConta"
        />
        <input-select
          v-model="form.areaAcreditacaoId"
          class="col-12 col-md-2"
          :label="$t('modulos.tipo_instrumento.formulario.area_de_acreditacao')"
          :options="opcoes.areaAcreditacoes"
          :loading="loadingAreaAcreditacoes"
          :disabled="visualizarOuParticipanteLogadoConta"
        />
        <input-radio
          v-model="form.flagPontosDuplicados"
          :divider="false"
          class="col-2"
          :options="opcoes.booleano"
          :label="$t('modulos.tipo_instrumento.formulario.pontos_duplicados') + ' *'"
          :disabled="visualizarOuParticipanteLogadoConta"
        />
        <input-radio
          v-model="form.flagPontosSomenteNoTipo"
          :divider="false"
          class="col-2"
          :options="opcoes.booleano"
          :label="$t('modulos.tipo_instrumento.formulario.criar_pontos_tipo') + ' *'"
          :disabled="visualizarOuParticipanteLogadoConta"
        />
        <input-combobox-text
          ref="input-itens"
          v-model="form.tags"
          class="col-8"
          :label="$t('modulos.tipo_instrumento.formulario.tags')"
          multiple
          :disabled="visualizarOuParticipanteLogadoConta"
          chips
        />
        <input-textarea
          v-model="form.observacao"
          class="col-12"
          :label="$t('modulos.tipo_instrumento.formulario.observacao')"
          :disabled="visualizarOuParticipanteLogadoConta"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import ProcedimentoService from '@common/services/cadastros/ProcedimentoService';
import SetorService from '@common/services/cadastros/SetorService';
import AreaAcreditacaoService from '@common/services/cadastros/AreaAcreditacaoService';
import { InputClassificacaoInstrumento } from '@components/inputs'; 

export default {
  components: { InputClassificacaoInstrumento },
  props: {
    form: {},
    participanteLogadoConta: { type: Boolean, default: false },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingProcedimentos: true,
      loadingSetores: true,
      loadingClassificacoes: true,
      loadingAreaAcreditacoes: true,
      opcoes: {
        procedimentos: [],
        setores: [],
        areaAcreditacoes: [],
        booleano: [
          {
            text: 'Sim',
            value: true,
          },
          {
            text: 'Não',
            value: false,
          },
        ],
      },
    };
  },
  computed: {
    visualizarOuParticipanteLogadoConta() {
      return this.visualizar ? this.visualizar : !this.participanteLogadoConta;
    }
  },
  mounted() {
    this.listarProcedimentos();
    this.listarSetores();
    this.listarAreaAcreditacoes();
  },
  methods: {
    listarProcedimentos: function () {
      ProcedimentoService.listar()
        .then((res) => {
          this.opcoes.procedimentos = new DropdownModel(
            res.data.items,
            'codigo'
          );
        })
        .finally(() => {
          this.loadingProcedimentos = false;
        });
    },
    listarSetores: function () {
      SetorService.listar()
        .then((res) => {
          this.opcoes.setores = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.loadingSetores = false;
        });
    },
    listarAreaAcreditacoes: function () {
      AreaAcreditacaoService.listar()
        .then((res) => {
          this.opcoes.areaAcreditacoes = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.loadingAreaAcreditacoes = false;
        });
    },
  },
};
</script>
