<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      scroll-height="340px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      :input-focus="show"
      @paginar="listar"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
    />
  </div>
</template>
<script>
import SetorService from '@common/services/cadastros/SetorService';
import _ from 'lodash';
export default {
  props: {
    modalVisivel: { type: Boolean, default: false },
    multiplos: { type: Boolean, default: true },
    value: { type: [Array, Object], default: null },
    ocultarSetor: { type: String, default: '' },
    participanteId: { type: String, default: null },
  },
  data() {
    return {
      filtro: {},
      filtroAplicado: { filter: '' },
      selecionados: [],
      show: false,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_setor.tabela.nome'),
            sortable: false,
          },
          {
            value: 'tipoSetor.descricao',
            text: this.$t('componentes.input_setor.tabela.descricao'),
            sortable: false,
          },
          // {
          //   value: 'tipoSetor.posicao',
          //   text: this.$t('componentes.input_setor.tabela.posicao'),
          //   sortable: false,
          //   formatter: (v) =>
          //     helpers.PosicaoSequenciaSetorEnum.find((el) => el.value == v)
          //       .text,
          // },
          {
            value: 'participante.nome',
            text: this.$t('componentes.input_setor.tabela.participante'),
            sortable: false,
          },
          {
            value: 'sigla',
            text: this.$t('componentes.input_setor.tabela.sigla'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
    participanteId() {
      this.listar();
    },
    modalVisivel: {
      handler(v) {
        this.show = v;
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listar();
    },
    limparFiltro() {
      this.filtro = {};
      this.filtroAplicado = {};
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      if (this.participanteId) parametros.participanteId = this.participanteId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      SetorService.listarSetoresParticipantesConta(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          if (this.ocultarSetor) {
            this.tabela.dados = this.tabela.dados.filter(
              (item) => item.id != this.ocultarSetor
            );
          }

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
