<template lang="">
  <div class="mt-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <div>
        <div class="d-flex align-center">
          <botao-padrao
            v-if="podeExcluir"
            class="my-2 ml-2"
            outlined
            color="danger"
            @click="excluirRegistros"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
            {{ $t('geral.botoes.excluir') }}
          </botao-padrao>
        </div>
      </div>
      <botao-padrao 
        v-if="participanteLogadoConta" 
        :disabled="visualizar"
        @click="associarDessasociar" 
      >
        {{ $t('modulos.tipo_instrumento.botoes.procedimentos') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      ref="tabela"
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="colunasTabela"
      :mostrar-checkbox="participanteLogadoConta"
      :sem-acoes="!participanteLogadoConta"
      :por-pagina="tabela.porPagina"
      sem-paginacao
      :disabled="visualizar"
    />
    <ModalProcedimentos
      ref="modal-procedimentos"
      @associarProcedimentos="associarProcedimentos"
    />
  </div>
</template>
<script>
import ModalProcedimentos from './Modal/ModalProcedimentos.vue';

export default {
  components: { ModalProcedimentos },
  props: {
    form: {},
    participanteLogadoConta: { type: Boolean, default: false },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: this.form.tipoInstrumentoSequenciaSetor,
        selecionados: [],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    colunasTabela: function () {
      const colunas = [
        {
          value: 'codigo',
          text: this.$t('modulos.tipo_instrumento.tabela.procedimentos.codigo'),
          sortable: true,
          width: '25%',
        },
        {
          value: 'titulo',
          text: this.$t('modulos.tipo_instrumento.tabela.procedimentos.titulo'),
          sortable: true,
        },
      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
  },
  mounted() {
    this.tabela.dados = this.form.procedimentosTabela;
  },
  methods: {
    associarDessasociar() {
      this.$refs['modal-procedimentos'].abrirModal(this.form);
    },
    associarProcedimentos(procedimentos) {
      procedimentos.forEach((procedimento) => {
        let procedimentoJaInserido = this.form.procedimentos.find(
          (el) => el.procedimentoId == procedimento.id
        );

        if (!procedimentoJaInserido) {
          this.form.procedimentos.push({
            id: null,
            procedimentoId: procedimento.id,
          });
          this.form.procedimentosTabela.push(procedimento);
          this.tabela.dados = this.form.procedimentosTabela;
        }
      });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.procedimentos.splice(
            this.form.procedimentos.indexOf(element),
            1
          );
          this.form.procedimentosTabela.splice(
            this.form.procedimentosTabela.indexOf(element),
            1
          );
        });
        this.tabela.dados = this.form.procedimentosTabela;
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
