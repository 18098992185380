<template lang="">
  <modal-padrao
    ref="modal-procedimentos"
    max-width="70%"
    :titulo="$t('modulos.tipo_instrumento.tabela.procedimentos.procedimentos')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    :ok-desabilitado="!tabela.selecionados[0]"
    @ok="associarProcedimentos"
  >
    <div style="min-height: 381px">
      <tabela-padrao-prime-vue
        v-model="tabela.selecionados"
        class="mt-2"
        scroll-height="290px"
        :colunas="tabela.colunas"
        :dados="tabela.dados"
        selecionar-colunas
        filtros-com-colunas
        :quantidade-itens="tabela.quantidadeItens"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        :mostra-header="true"
        :mostrar-acoes="false"
        @paginar="listarProcedimentos"
        @filtroGlobal="(v) => (filtroAplicado.filter = v)"
        @filtrarGlobal="(_) => listarProcedimentos()"
      />
    </div>
  </modal-padrao>
</template>

<script>
import ProcedimentoService from '@common/services/cadastros/ProcedimentoService';

export default {
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.procedimentos.codigo'
            ),
            sortable: true,
          },
          {
            value: 'titulo',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.procedimentos.titulo'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      referenciaForm: null,
      moreParams: {},
    };
  },
  methods: {
    abrirModal: function (form) {
      this.listarProcedimentos();
      this.referenciaForm = form;
      this.$refs['modal-procedimentos'].abrirModal();
    },
    listarProcedimentos: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      ProcedimentoService.listar(parametros).then((res) => {
        this.tabela.selecionados = [];
        this.tabela.dados = res.data.items;

        this.tabela.quantidadeItens = res.data.totalItems;
        this.tabela.quantidadePaginas = res.data.totalPages;
        this.tabela.paginaAtual = this.paginaAtual;
        this.tabela.porPagina = this.porPagina;
      });
    },
    associarProcedimentos: function () {
      this.$emit('associarProcedimentos', [...this.tabela.selecionados]);
      this.$refs['modal-procedimentos'].fecharModal();
    },
    onRowClick() {
      this.tabela.selecionados = this.$refs['tabela-procedimento'].selectedRows;
    },
  },
};
</script>
<style scoped>
:deep(.p-checkbox .p-checkbox-box) {
  margin-top: 2px;
  width: 16px !important;
  height: 16px !important;
}

:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  overflow-y: scroll;
  height: 150px;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
