<template>
  <div>
    <template>
      <div class="mb-8 ">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
        <div v-if="form.id">
          <v-chip
            class="mr-1"
            small
          >
            Tipo: {{ form.nome }}
          </v-chip>
        </div>
      </div>
      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
      >
        <v-tab>
          {{
            $t(
              'modulos.tipo_instrumento.formulario.abas.dados_do_tipo_de_instrumento'
            )
          }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.tipo_instrumento.formulario.abas.procedimentos') }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.tipo_instrumento.formulario.abas.faixas') }}
        </v-tab>
        <v-tab v-if="participanteLogadoConta">
          {{
            $t('modulos.tipo_instrumento.formulario.abas.sequencia_de_setores')
          }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="indiceAba">
        <v-tab-item>
          <dados-instrumentos
            ref="dados-instrumentos"
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
        <v-tab-item>
          <Procedimentos
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
        <v-tab-item>
          <Faixas
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
        <v-tab-item v-if="participanteLogadoConta">
          <SequenciaSetores
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
      </v-tabs-items>
      <template>
        <v-divider class="my-5" />
        <div
          class="d-flex align-center justify-end"
         >
          <botao-padrao
            outlined
            color="secondary"
            class="mr-2"
            @click="cancelar"
          >
            <v-icon>{{ visualizar ? '$mdiArrowLeft' : '$mdiCancel' }}</v-icon>
            {{ botaoCancelarOuVoltar }}
          </botao-padrao>
          <botao-padrao
            v-if="!visualizar && participanteLogadoConta"
            @click="confirmacaoSalvar"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{ $t('geral.botoes.salvar') }}
          </botao-padrao>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { TipoInstrumentoModel } from '@common/models/cadastros/TipoInstrumentoModel';
import DadosInstrumentos from './components/DadosInstrumentos.vue';
import Faixas from './components/Faixas.vue';
import SequenciaSetores from './components/SequenciaSetores.vue';
import Procedimentos from './components/Procedimentos.vue';
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService.js';
import helpers from '@common/utils/helpers';
export default {
  components: { DadosInstrumentos, Faixas, SequenciaSetores, Procedimentos },
  props: {
    id: { type: String, default: '' },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      indiceAba: 0,
      valido: false,
      form: new TipoInstrumentoModel({}),
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    tituloFormulario() {
      if (this.visualizar || !this.participanteLogadoConta)
        return this.$t('modulos.tipo_instrumento.titulos.visualizar');
      if (this.id) return this.$t('modulos.tipo_instrumento.titulos.editar');
      return this.$t('modulos.tipo_instrumento.titulos.novo');
    },
    tooltipBotaoSalvar() {
      return this.valido ? '' : this.$t('modulos.tipo_instrumento.validacoes.formulario_invalido');
    },
    botaoCancelarOuVoltar() {
      return this.visualizar || !this.participanteLogadoConta ? this.$t('geral.botoes.voltar') : this.$t('geral.botoes.cancelar');
    },
  },
  watch: {
    id() {
      if (this.id) this.buscar();
    },
  },
  mounted() {
    if (!this.visualizar) this.visualizar = this.$route.path.includes('visualizar');
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'TipoInstrumento', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'TipoInstrumento', 'Inserir');

    this.verificarEdicaoFaixa();
  },
  methods: {
    verificarEdicaoFaixa: async function (){
      await this.$nextTick();
      if(this.$route.query?.editarFaixa) this.indiceAba = 2;
    },
    mostrarIdInvalido() {
      this.toastErro(this.$t('modulos.tipo_instrumento.erros.id_invalido'));
    },
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoInstrumentoService.buscar(this.id)
        .then((res) => {
          this.form = new TipoInstrumentoModel(res.data);
        })
        .catch(() => {
          this.mostrarIdInvalido();
        })
        .finally(() => {
          this.valido = true
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar() {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarFormulario() {
      if(!this.valido) {
        this.indiceAba = 0;
        return false;
      }
      if (this.form.procedimentos.length == 0) {
        this.toastAlerta(
          this.$t('modulos.tipo_instrumento.validacoes.obrigatorio_procedimento')
        );
        this.indiceAba = 1
        return false;
      }
      if (this.form.faixa.length == 0) {
        this.toastAlerta(
          this.$t('modulos.tipo_instrumento.validacoes.obrigatorio_faixa')
        );
        this.indiceAba = 2;
        return false;
      }

      if(!this.validarSequenciaSetores()) return false;

      return true;
    },
    validarSequenciaSetores(){
      const length = this.form.tipoInstrumentoSequenciaSetor.length;

      if (length < 2) {
        this.toastAlerta(
          this.$t('modulos.tipo_instrumento.validacoes.obrigatorio_setor')
          );
          this.indiceAba = 3;
          return false;
      }
      return true
    },
    salvar() {
      if (this.$refs['dados-instrumentos'])
          this.valido = this.$refs['dados-instrumentos'].$refs.form.validate();
      if (!this.validarFormulario()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoInstrumentoService.salvar(this.form.request)
        .then(() => {
          if (!this.form.id) {
            this.toastSucesso(
              this.$t(`modulos.tipo_instrumento.cadastro_sucesso`)
            );
          } else {
            this.toastSucesso(
              this.$t(`modulos.tipo_instrumento.edicao_sucesso`)
            );
          }

          if(this.$route.query?.editarFaixa && this.$route.params?.modeloInstrumentoId){
            this.$router.push({
              name: 'modelo-instrumento-editar',
              params: { id: this.$route.params?.modeloInstrumentoId },
              query: { editarFaixa: true }
            });
            return;
          }
          this.$router.push({ name: 'tipo-instrumento' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      if (!this.participanteLogadoConta)
        return this.$router.push({ name: 'tipo-instrumento' });
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-instrumento' });
      });
    },
  },
};
</script>
