<template lang="">
  <modal-padrao
    ref="modal-pontos"
    max-width="70%"
    :style="{'height: 600px;': true}"
    :titulo="$t('modulos.tipo_instrumento.tabela.pontos_de_calibracao')"
    :subtitulo="$t('modulos.tipo_instrumento.tabela.faixas.faixa') + ': ' + referenciaFaixa.descricao"
    :titulo-ok="textoBotaoOk"
    :mostra-botao-salvar="indiceAba === 1"
    :titulo-cancelar="`${$t('geral.botoes.voltar')} (ESC)`"
    prevent-default
    :persistente="indiceAba === 1"
    @ok="salvar_sair"
    @cancelar="irParaTabelaOuFechar"
    @keydown="eventoUsabilidade"
  >
    <v-tabs
      v-if="false"
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        Tabela
      </v-tab>
      <v-tab>
        Formulario
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <div class="d-flex flex-row justify-space-between">
          <div>
            <botao-padrao
              v-if="podeExcluir"
              class="my-2"
              outlined
              color="danger"
              @click="excluirRegistros"
            >
              <v-icon>$mdiTrashCanOutline</v-icon>
              {{ $t('geral.botoes.excluir') }}
            </botao-padrao>
          </div>
          <div class="d-flex align-center flex-row justify-space-between w-auto mt-2">
            <botao-padrao
              class="mr-0"
              @click="abrirNovo"
            >
              {{ $t('modulos.tipo_instrumento.botoes.novo_ponto') }}
            </botao-padrao>
          </div>
        </div>
        <tabela-padrao-prime-vue
          ref="tabela"
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="tabela.colunas"
          :quantidade-itens="tabela.quantidadeItens"
          :quantidade-paginas="tabela.quantidadePaginas"
          paginacao-em-memoria
          sem-paginacao
          ajustar-dropdown-acima
          :pagina-atual="tabela.paginaAtual"
          sort-field="valor"
          :sort-order="1"    
        >
          <template v-slot:acoes="{ slotProps }">
            <dropdown-padrao
              text
              color="secondary"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>
              <dropdown-padrao-item @click="abrirEditar(slotProps.data)">
                {{ $t('geral.botoes.editar') }}
              </dropdown-padrao-item>
            </dropdown-padrao>
          </template>
        </tabela-padrao-prime-vue>
      </v-tab-item>
      <v-tab-item>
        <ModalPontosCalibracaoForm
          ref="modal-pontos-form"
          :tipoInstrumento="form"
        />
      </v-tab-item>
    </v-tabs-items>
    <template v-slot:botaoAdicional>
      <botao-padrao
        v-if="indiceAba === 1"
        color="primary"
        :class="{'ml-2': !EhTelaPequena}"
        :width="EhTelaPequena ? 240 : 'auto'"
        @click="salvar_continuar"
      >
        {{ `${$t('geral.botoes.salvar_continuar')} (F3)` }}
      </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers'
import ModalPontosCalibracaoForm from './ModalPontosCalibracaoForm'

export default {
  components: {
    ModalPontosCalibracaoForm
  },
  props: {
    form: { type: Object, default: null },
  },
  data() {
    return {
      selecionados: [],
      referenciaFaixa: {},
      indiceAba: 0,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'valor',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'descricao',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.descricao'),
            sortable: true,
          },
          {
            value: 'tipoPonto',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.tipo_ponto'),
            sortable: true,
            formatter: (v) => helpers.TipoPontoEnum.find(el => el.value == v)?.text
          },
          {
            value: 'origemPonto',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.origem_ponto'),
            sortable: true,
            formatter: (v) => helpers.OrigemPontoEnum.find(el => el.value == v)?.text
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    }
  },
  computed: {
    textoBotaoOk() {
      return this.indiceAba === 1 ? `${this.$t('geral.botoes.salvar_voltar')} (F2)` : this.$t('geral.botoes.confirmar');
    },
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name)
    },
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  methods: {
    abrirModal: function (faixa) {
      if (!faixa.tipoInstrumentoFaixaPonto) {
        faixa.tipoInstrumentoFaixaPonto = [];
      }
      this.referenciaFaixa = faixa;
      this.tabela.dados = faixa.tipoInstrumentoFaixaPonto
      this.tabela.quantidadeItens = faixa.tipoInstrumentoFaixaPonto.length
      this.tabela.quantidadePaginas = Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0
      this.$refs['modal-pontos'].abrirModal();
    },
    irParaFormulario: async function () {
      this.indiceAba = 1;
      await this.$nextTick(); await this.$nextTick()
      this.$refs['modal-pontos-form'].$refs['input-valor']?.focus();
    },
    irParaTabelaOuFechar: function () {
      if (this.indiceAba === 1) return this.indiceAba = 0;
      this.$refs['modal-pontos'].fecharModal();
    },
    abrirEditar: async function (ponto) {
      this.irParaFormulario();
      await this.$nextTick(); await this.$nextTick()
      this.$refs['modal-pontos-form'].editarPonto(ponto, this.referenciaFaixa);
    },
    abrirNovo: async function () {
      this.irParaFormulario();
      await this.$nextTick(); await this.$nextTick()
      this.$refs['modal-pontos-form'].novoPonto(this.referenciaFaixa);
    },
    eventoUsabilidade(event) {
      switch (event.key) {
        case 'F3':
          event.preventDefault()
          this.salvar_continuar()
          break;
        case 'F2':
          event.preventDefault()
          this.salvar_sair()
          break;
        case 'Escape':
          event.preventDefault()
          this.irParaTabelaOuFechar()
          break;
      }
    },
    salvar_sair: async function () {
      const valido = this.$refs['modal-pontos-form']?.salvar_continuar();
      await this.$nextTick();
      if (!valido) return;
      this.irParaTabelaOuFechar();
      this.atualizarPaginacao();
    },
    salvar_continuar() {
      this.$refs['modal-pontos-form']?.salvar_continuar();
      this.atualizarPaginacao();
    },
    atualizarPaginacao(){
      this.tabela.quantidadeItens = this.referenciaFaixa.tipoInstrumentoFaixaPonto.length
      this.tabela.quantidadePaginas = Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.referenciaFaixa.tipoInstrumentoFaixaPonto.splice(this.referenciaFaixa.tipoInstrumentoFaixaPonto.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
        this.atualizarPaginacao();
      });
    },
  },
}
</script>
  
  