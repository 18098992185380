import _ from 'lodash';
import { DropDownIdNomeSimbolo } from '@/common/models/geral/DropDownIdNomeSimbolo';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export class TipoInstrumentoModel {
  constructor({
    id,
    nome,
    procedimentos = [],
    procedimentosTabela = [],
    areaAcreditacaoId,
    flagPontosDuplicados = false,
    flagPontosSomenteNoTipo = false,
    classificacaoInstrumento,
    observacao,
    tipoInstrumentoSequenciaSetor = [],
    faixa = [],
    tags = [],
    procedimento = [],
  }) {
    this.id = id;
    this.nome = nome;
    this.procedimentos = procedimentos;
    this.procedimentosTabela = procedimentosTabela;
    this.areaAcreditacaoId = areaAcreditacaoId;
    this.flagPontosDuplicados = flagPontosDuplicados;
    this.flagPontosSomenteNoTipo = flagPontosSomenteNoTipo;
    this.classificacaoInstrumento = classificacaoInstrumento ? new DropdownModel(classificacaoInstrumento, "descricao") : null;
    this.observacao = observacao;
    this.tipoInstrumentoSequenciaSetor = tipoInstrumentoSequenciaSetor;
    this.faixa = faixa;
    this.tags = tags;
    this.procedimentosTabela = procedimento

    this.faixa.forEach((el) => {
      if (el.unidadeMedida) el.unidadeMedida = new DropDownIdNomeSimbolo(el.unidadeMedida);
      if (el.id) el.flagJaExistente = true;
      if (!el.pontos) el.pontos = [];

      if (el.tipoInstrumentoFaixaPonto) {
        el.tipoInstrumentoFaixaPonto.forEach((ponto) => {
          if (ponto.id) ponto.flagJaExistente = true;
        });
      }
    });

    procedimento?.forEach(item => {
      this.procedimentos.push(
        {
          id: item.id,
          procedimentoId: item.id,
        }
      );
    })

    const cloneSetores = _.cloneDeep(this.tipoInstrumentoSequenciaSetor.filter(el => el.localExecucao !== null));
    this.tipoInstrumentoSequenciaSetor = [];
    cloneSetores.forEach((item) => {
      this.tipoInstrumentoSequenciaSetor.push({
        flagJaExistente: true,
        id: item.id,
        setorId: item.setor.id,
        nome: item.setor.nome,
        ordem: item.ordem,
        sigla: item.setor.sigla,
        tipoSetor: item.setor.tipoSetor,
        localExecucaoId: item.localExecucao?.id,
        participanteId: item.participante?.id,
        gatilhoMovimentacao: item.gatilhoMovimentacao,
        participante: item.setor.participante
      })
    });
    this.tipoInstrumentoSequenciaSetor.sort((a, b) => a.ordem - b.ordem);
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.classificacaoInstrumentoId = retorno.classificacaoInstrumento?.value;
    retorno.faixa.forEach((faixa) => {
      if (!faixa.flagJaExistente) faixa.id = null;
      if (faixa.tipoInstrumentoFaixaPonto)
        faixa.tipoInstrumentoFaixaPonto.forEach((ponto) => {
          if (!ponto.flagJaExistente) ponto.id = null;
        });
      faixa.pontos = _.cloneDeep(faixa.tipoInstrumentoFaixaPonto);
      faixa.unidadeMedidaId = faixa.unidadeMedida.value;
    });

    retorno.tipoInstrumentoSequenciaSetor.forEach((setor, index) => {
      if (!setor.flagJaExistente) setor.id = null;
      setor.ordem = index + 1;
    });

    return retorno;
  }
}
