<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <modal-padrao
    ref="modal-faixa"
    max-width="60%"
    :titulo="tituloForm"
    :titulo-ok="$t('geral.botoes.salvar_f2')"
    :titulo-cancelar="$t('geral.botoes.cancelar_modal')"
    @ok="confirmacaoSalvar"
    @modal-fechado="destruirConteudo"
    @f2-pressionado="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row mt-2"
    >
      <v-form
        ref="amplitudeForm"
        class="row col-12 d-flex"
      >
        <input-text
          v-model="form.amplitudeMinima"
          class="col-12 col-md-6 pl-0"
          type="number"
          :label="
            $t('modulos.tipo_instrumento.formulario.faixa.amplitude_minima')
          "
          obrigatorio
          @input="validarAmplitude"
        />
        <input-text
          v-model="form.amplitudeMaxima"
          class="col-12 col-md-6 pr-0"
          type="number"
          :label="
            $t('modulos.tipo_instrumento.formulario.faixa.amplitude_maxima')
          "
          :regras-personalizadas="regraValidacaoAmplitude()"
          obrigatorio
          @input="validarAmplitude"
        />
      </v-form>
      <input-text
        v-model="form.resolucao"
        type="number"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.faixa.resolucao')"
        obrigatorio
      />
      <input-unidade-medida
        v-model="form.unidadeMedida"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.faixa.unidade_de_medida')"
        obrigatorio
        :multiplos="false"
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-12"
        :tooltip="$t('modulos.tipo_instrumento.formulario.tooltip')"
        :label="$t('modulos.tipo_instrumento.formulario.descricao')"
        :disabled="disabled"
        obrigatorio
        :max="300"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import InputUnidadeMedida from '@components/inputs/especificos/InputUnidadeMedida'
import cloneDeep from 'lodash/cloneDeep';
import helpers from '@/common/utils/helpers';

export default {
  components: { InputUnidadeMedida },
  data() {
    return {
      modalAberto: false,
      disabled: true,
      watcherAtivo: false,
      form: {},
      edicao: false,
      descricao: null,
      formReferencia: {},
      watchs: {
        form: null
      },
    };
  },
  watch: {
    form: {
      handler(){
      this.disabledDescricao();
    },
    deep: true
  },
  "form.amplitudeMinima": function() {
    this.setarDescricao();
  },
  "form.amplitudeMaxima": function() {
    this.setarDescricao();
  },
  "form.resolucao": function() {
    this.setarDescricao();
  },
  "form.unidadeMedida": {
    handler() {
      this.setarDescricao();
    },
    deep: true
    }
  },
  computed:{
    tituloForm: function() {
      return this.edicao ?
       this.$t('modulos.tipo_instrumento.formulario.faixa.editar_faixa')
       : this.$t('modulos.tipo_instrumento.formulario.faixa.nova_faixa');
    },
  },
  mounted() {
    window.addEventListener('keydown', this.pressionarf2);
    this.iniciarForm();

  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.pressionarf2);
  },
  methods: {
    pressionarf2(event) {
      if (event.key === 'F2') {
        this.confirmacaoSalvar();
      }
    },
    destruirConteudo: async function(){
      await this.$nextTick();
      this.watcherAtivo = false;
      this.modalAberto = false;
    },
    f2Pressionado() {
      console.log("faixa form")
    this.$emit('f2-pressionado');
  },
    abrirModal: async function(faixa) {
      this.modalAberto = true;
      await this.$nextTick();
      this.iniciarForm();
      this.edicao = false;
      if (faixa) {
        this.valido = true;
        this.edicao = true;
        this.formReferencia = faixa;
        this.form.descricao = faixa?.descricao
        this.form = cloneDeep(faixa);
      }
      this.$refs['modal-faixa'].abrirModal();
      this.watcherInit();
    },
    watcherInit: async function(){
      await this.$nextTick();
      this.watcherAtivo = true;
      this.disabledDescricao()
    },
    iniciarForm() {
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        unidadeMedida: null,
        amplitudeMinima: null,
        pontos: [],
        amplitudeMaxima: null,
        resolucao: null,
        descricao: null,
      };
      this.disabled = true;
      this.$refs.form?.resetValidation();
      this.$refs.amplitudeForm?.resetValidation();
    },
    salvar() {
      if (!this.edicao) this.$emit('salvar-novo', this.form);
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-faixa'].fecharModal();
    },
    validarAmplitude() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima)
        return;
      this.$refs.amplitudeForm.validate();
    },
    confirmacaoSalvar() {

      if (!this.$refs.form.validate() || !this.$refs.amplitudeForm.validate()) return;
      // if (this.edicao) {
      //   this.confirmarSalvar().then(() => {
      //     this.salvar();
      //   });
      // } else
      if (!this.modalAberto) return;
      this.salvar();
    },
    regraValidacaoAmplitude() {
      const regraAmplitude = (amplitudeMaxima) => {
        if (parseFloat(amplitudeMaxima) < parseFloat(this.form.amplitudeMinima))
          return this.$t(
            'modulos.tipo_instrumento.validacoes.amplitude_maxima'
          );
        else return true;
      };
      return [regraAmplitude];
    },
    setarDescricao: async function () {
      if(!this.watcherAtivo) return;
      if(this.form.amplitudeMinima == null ||
        this.form.amplitudeMaxima == null ||
        this.form.unidadeMedida == null ||
        this.form.resolucao == null) return;

        this.form.descricao = `(${(helpers.formatarNumeroComPrecisao(this.form.amplitudeMinima))} a ${helpers.formatarNumeroComPrecisao(this.form.amplitudeMaxima)}) ${this.form.unidadeMedida.simbolo} ; ${helpers.formatarNumeroComPrecisao(this.form.resolucao)}`;
    },
    disabledDescricao() {
      this.disabled = !(
        this.form.unidadeMedida != null &&
        this.form.resolucao != null &&
        this.form.amplitudeMaxima  != null &&
        this.form.amplitudeMinima  != null
      );
    },
  },
};
</script>
<style scoped>
  .row {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
</style>