<template lang="">
  <div class="mt-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          :disabled="visualizar || !participanteLogadoConta"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        v-if="participanteLogadoConta"
        :disabled="visualizar || !participanteLogadoConta"
        @click="abrirNovo"
      >
        {{ $t('modulos.tipo_instrumento.botoes.adicionar_faixa') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="dadosTabela"
      mostrar-qtd-registros
      filtro-geral
      class="mt-2"
      :colunas="colunasTabela"
      :filters="tabela.filters"
      paginacao-em-memoria
      sem-paginacao
      :por-pagina="tabela.porPagina"
      :pagina="tabela.paginaAtual"
      :quantidade-itens="form.faixa.length"
      ajustar-altura-linha
      :mostrar-acoes="visualizar ? false : participanteLogadoConta"
      :global-filters="colunasTabela.map(c => c.value)"
      :mostrar-seletor="!visualizar"
    >
      <template
        v-if="participanteLogadoConta"
        v-slot:acoes="{ slotProps }"
      >
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirEditar(slotProps.data)">
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            @click="abrirPontosCalibracao(slotProps.data)"
          >
            {{ $t('modulos.tipo_instrumento.tabela.pontos_de_calibracao') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>

    <FaixaForm
      ref="modal-faixa"
      @salvar-novo="salvarNovaFaixa"
    />
    <ModalPontosCalibracao
      ref="modal-pontos"
      :form="form"
    />
  </div>
</template>
<script>
import FaixaForm from './Modal/FaixaForm';
import ModalPontosCalibracao from './Modal/ModalPontosCalibracao.vue';
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';
import _ from 'lodash';

export default {
  components: { FaixaForm, ModalPontosCalibracao },
  props: {
    form: { type: Object, default: null },
    participanteLogadoConta: { type: Boolean, default: false },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: [],
        selecionados: [],
        porPagina: 10,
        paginaAtual: 1,
        filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    colunasTabela() {
      const colunas = [
        {
          value: 'descricao',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.faixa'),
          sortable: true,
        },
        {
          value: 'amplitudeMinima',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_minima'),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'amplitudeMaxima',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_maxima'),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'unidadeMedida',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.unidade_medida'),
          sortable: true,
          formatter: v => `${v?.text} (${v?.simbolo})`
        },
        {
          value: 'resolucao',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.resolucao'),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tipoInstrumentoFaixaPonto',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.quantidade_pontos'),
          sortable: true,
          formatter: v => v?.length ?? 0
        }
      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
    dadosTabela(){
      return _.sortBy(this.form.faixa, ['amplitudeMinima', 'amplitudeMaxima', 'resolucao' , 'descricao']);
    }
  },
  mounted() {
    this.dados = this.form.faixa;
  },
  methods: {
    abrirNovo() {
      this.$refs['modal-faixa'].abrirModal();
    },
    abrirEditar(faixa) {
      this.$refs['modal-faixa'].abrirModal(faixa);
    },/*
    salvarFaixa:function(){
      this.$refs.modalFaixa.salvar();
    }, */

    salvarNovaFaixa(faixa) {
      this.form.faixa.push(faixa);
    },
    abrirPontosCalibracao(faixa){
      this.$refs['modal-pontos'].abrirModal(faixa);
    },
    excluir() {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.faixa.splice(this.form.faixa.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
